/* 消息相关 */
export default [
    {
        path: 'message/index',
        name: 'messageIndex',
        meta: {
            title: '消息',
            isShowTab: true,
            keepAlive: false,
        },
        component: () => import('@/views/message/index.vue')
    },
    {
        path: 'message/interactive',
        name: 'messageInteractive',
        meta: {
            title: '互动消息',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/interactive.vue')
    },
    {
        path: 'message/system',
        name: 'messageSystem',
        meta: {
            title: '系统通知',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/system.vue')
    },
    {
        path: 'message/order',
        name: 'messageOrder',
        meta: {
            title: '订购通知',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/order.vue')
    },
    {
        path: 'message/orderDetails',
        name: 'messageOrderDetails',
        meta: {
            title: '通知详情',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/orderDetails.vue')
    },
    {
        path: 'message/earnings',
        name: 'messageEarnings',
        meta: {
            title: '收益通知',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/earnings.vue')
    },
    {
        path: 'message/systemDetails',
        name: 'messageSystemDetails',
        meta: {
            title: '通知详情',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/systemDetails.vue')
    },
    {
        path: 'message/liveEarnings',
        name: 'liveEarnings',
        meta: {
            title: '收益',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/liveEarnings.vue')
    },
    {
        path: 'message/msgList',
        name: 'msgList',
        meta: {
            title: '通知',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/msgList.vue')
    },
    {
        path: 'message/msg',
        name: 'msg',
        meta: {
            title: '通知',
            isShowTab: false,
            keepAlive: false,
        },
        component: () => import('@/views/message/msg.vue')
    },

]
/*消息相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    interactiveApi: (mechanismId, data) => {//互动消息列表
        return axios.requestGet('/v1/messages/interact-list/' + mechanismId, data);
    },
    earningsApi: (mechanismId, data) => {//收益消息列表
        return axios.requestGet('/v1/messages/list/' + mechanismId + '/type/2', data);
    },
    orderApi: (mechanismId, data) => {//订购消息列表
        return axios.requestGet('/v1/messages/list/' + mechanismId + '/type/1', data);
    },
    systemApi: (mechanismId, type, data) => {//系统消息列表
        return axios.requestGet('/v1/messages/list/' + mechanismId + '/type/' + type, data);
    },
    SystemInforms: (data) => {//系统消息列表
        return axios.get(`/api/api/system_message/list`, data);
    },
    // systemDetailsApi: (id) => {//系统消息详情
    //     return axios.requestGet('/v1/messages/' + id,);
    // },
    systemDetailsApi: (id) => {//系统消息详情
        return axios.get(`/api/api/information/detail/${id}`);
    },
    awardApi: (id, data) => {//消息-直播打赏-列表
        return axios.get('/api/api/information/live/award/list/' + id, data);
    },
    unreadApi: () => {//消息-未读消息数
        return axios.get('/api/api/information/unread/count');
    },
    messageListApi: (data) => {//通知列表
        return axios.get('/api/api/message/list', data);
    },
    messageInfoApi: (data) => {//通知消息详情
        return axios.get('/api/api/message/info',data);
    },
}
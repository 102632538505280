/*  个人主页相关 + PK榜 */
import axios from '../axios'

export default {
  userUpdateApi: (data) => {// 个人主页修改
    return axios.post('/api/api/user/update', data);
  },
  getUserInfo: (data) => {// 个人主页获取
    return axios.get('/api/api/user/info', data);
  },
  getUserInfoData: (data) => {// 个人主页获取-新接口
    return axios.get('api/api/user/detail', data);
  },
  getPersona: (data) => {// 个人主页x获取-新排序接口
    return axios.get('api/api/user/detail/v2', data);
  },
  getFriendList: (data) => {// 人脉链接列表
    return axios.get('/api/api/user/friend/list', data);
  },
  // 账户列表
  getAmountList: (data) => {
    return axios.get('/api/api/center/amount', data);
  },
  getCategories: (data) => {// 我的课程 TAB标签
    return axios.get('/api/api/center/courses/categories', data);
  },
  getActivityJoin: (data) => {// 购买的活动
    return axios.requestGet('/v1/ucentor/actives/join', data);
  },
  getActivityJoinPage: (data) => {// 购买的活动-有分页
    return axios.get('/products/activity/my_order/activity', data);
  },
  getTaskJoin: (id, data) => {// 参与的打卡
    return axios.requestGet('/v1/ucentor/task-users/' + id, data);
  },
  getMeetingList: (type, data) => {// 我的约见
    return axios.get(`/api/api/appoint/${type}/loggor`, data);
  },
  setAffirm: (type, data) => {// 确认约见
    return axios.post('/api/api/appoint/' + type + '/affirm', data);
  },
  expertAuth: (data) => {// 获取用户是否是专家
    return axios.get('/api/api/appoint/expert/auth', data);
  },
  saveAppoint: (data) => axios.post('/api/api/appoint/options', data),// 我的约见 保存展示身份
  resellGoods: (data) => axios.get('/api/api/resell-goods', data),// 我的课程 转赠转卖
  // getColumn: (data) => axios.get('/api/api/center/courses/columns', data),// 我的课程 专栏
  getColumn: (data) => axios.get('/products/auth-progress/teacher', data),// 我的课程 专栏
  getServiceInfo: () => axios.get('/api/api/center/courses/columns/contact-person'),//我的课程-专栏-联系客服 
  // getVideo: (data) => axios.requestGet('/v1/ucentor/orders/buy/1', data),//我的课程-视频-
  getVideo: (data) => axios.get('/products/auth-progress/video', data),//我的课程-视频-
  liveCategories: (data) => axios.get('/api/api/center/courses/lives/categories', data),//我的课程-直播分类
  coursesLives: (data) => axios.get('/api/api/center/courses/lives', data),//我的课程-直播列表
  activityTabs: (data) => axios.get('/api/api/center/courses/activities/categories', data),//我的课程-活动分类
  taskTabs: (data) => axios.get('/api/api/center/courses/tasks/categories', data),//我的课程-打卡分类
  appointTabs: (data) => axios.get('/api/api/center/courses/appoints/categories', data),//我的课程-约见分类
  columnTabs: (data) => axios.get('/api/api/center/courses/columns/categories?version=1', data),//我的课程-专栏分类

  // PK榜
  getBlendList: (mechanismId, data) => {// PK榜列表
    return axios.requestGet('/v1/ranking-lists/blend-list/' + mechanismId, data);
  },
  getBlendDetails: (ranking_id, ranking_type) => {// 排行信息和榜单列表
    return axios.requestGet('/v1/ranking-lists/blend-details/' + ranking_id + '/' + ranking_type);
  },
  getBlendTitle: (ranking_id) => {// 获取排行title
    return axios.requestGet('/v1/ranking-lists/blend-title/' + ranking_id);
  },
  getBlendPrize: (ranking_id) => {// 获取排行奖品信息
    return axios.requestGet('/v1/ranking-lists/blend-prize/' + ranking_id);
  },
  // 引导加群
  getGruopList: (data) => {// 列表
    return axios.get('/api/api/spread/index', data);
  },
  spreadNews: (mechanismId, data) => {// 消息播放
    return axios.requestGet('/v1/ucentor/spread-rewards/news/' + mechanismId, data);
  },
  rewardsInfo: (mechanismId, data) => {// 邀请奖励
    return axios.requestGet('v1/ucentor/spread-rewards/details/' + mechanismId, data);
  },
  rewardsDetails: (mechanismId, stageId, data) => {// 邀请奖励详情
    return axios.requestGet('v1/ucentor/spread-rewards/detail/' + mechanismId + '/' + stageId, data);
  },
  inviteList: (data) => {// 邀请好友
    return axios.get('/api/api/spread/invite/list', data);
  },
  rulesDetails: (mechanismId, data) => {// 群规则
    return axios.requestGet('/v1/ucentor/spread-rewards/rules/' + mechanismId, data);
  },
  posters: (mechanismId, data) => {// 海报列表
    return axios.requestGet('/v1/posters/partner/' + mechanismId + '/17', data);
  },
  posterInfo: (posterId) => {// 海报信息
    return axios.get('/api/api/poster/' + posterId);
  },
  spreadsLink: (mechanismId, data) => {// 海报群组
    return axios.requestGet('/v1/wechat-spreads/link/' + mechanismId, data);
  },
  liveShareList: (data) => {// 邀请达人榜
    return axios.get('/api/api/anchor/goods_live', data);
  },
  liveInviteList: (data) => {// 邀请达人榜
    return axios.get('/api/api/goods_live_info/invitation_list', data);
  },
  liveFriendList: (goods_live_id, data) => {// 邀请达人榜详情
    return axios.get('/api/api/goods_live_info/invitation_list/' + goods_live_id, data);
  },
  // 我的收藏
  getCollectVideo: (data) => {//收藏的视频
    return axios.get('/api/api/column/collect', data);
  },
  getCollectMeet: (data) => {//收藏的约见
    return axios.get('/api/api/appoint/user/collect', data);
  },
  getPartialList: (data) => axios.get('/api/api/payment/batch/loggor', data),//我的分批列表
  getPartialDetails: (data) => axios.get('/api/api/payment/batch', data),//我的分批详情
  getOrderList: (data) => axios.get('/api/api/payment/batch/order/loggor', data),//获取分批付款列表数据
  stageList: (data) => axios.get('/api/api/teacher/activity/stage/list', data),//活动改期-获取分期列表
  stageUpdate: (data) => axios.put('/api/api/teacher/activity/stage/update', data),//活动改期-提交改期操作
  stagerRecord: (data) => axios.get('/api/api/teacher/activity/stage/record', data),//活动改期-获取分期修改记录
  activityTicket: (data) => axios.get('/api/api/center/courses/activity/checks', data),//活动验票-获取验票记录
  ticketInfo: (activityId, data) => axios.requestGet('/v1/actives/activity-info/' + activityId, data),//活动验票-验票员验票链接
  ticketStudentInfo: (data) => axios.get('/api/api/center/courses/activity/student', data),//活动验票-获取学员信息
  checkTicket: (data) => axios.post('/products/activity/check_ticket', data),//活动验票-获取验票信息
  // meetGetTime: (logger_id, data) => axios.get('/api/api/appoint/loggor/' + logger_id + '/time-periods/v2', data),//我的约见-获取时间
  meetGetTime: (meetId, curDay,data) => axios.get('/products/appoint/bookable_time/'+meetId+'/'+curDay,data),//我的约见-获取时间
  // meetSelectTime: (data) => axios.post('/api/api/appoint/user/datetime', data),//我的约见-选择时间 弃用-改用专家端的接putChangeTime
  meetConfirmTime: (logger_id) => axios.post('/api/api/appoint/loggor/' + logger_id + '/time-confirm'),//我的约见-确认时间

  getMeetDates: (data) => {//预约约见-天数
    return axios.get('/api/api/appoint/loggor/days', data);
  },
  meetCancelTime: (data) => axios.post('/api/api/appoint/user/datetime/cancel', data),//我的约见-取消时间
  repositoryPerson: () => axios.get('/api/api/center/courses/repository/contact-person'),//我的课程-资料-联系客服
  repositoryList: (data) => axios.get('/products/course/repository',data),//我的课程-资料列表
  repositorySort: () => axios.get('/api/api/center/courses/repository/categories'),//我的课程-资料分类
  getMeetDates2: (data) => {//预约约见-天数
    return axios.get('/products/appoint/expert/order/date', data);
  },
  getMeetPeriod: (data) => {//预约约见-不固定时间段
    return axios.get('/products/appoint/expert/order/times', data);
  },
}
